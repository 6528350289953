import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import React from 'react';

export const SkeletonLoader: React.FC = () => (
  <Grid
    container
    data-testid="skeleton-loader"
    sx={{
      height: 80,
      alignItems: 'center',
      paddingLeft: 5,
      position: 'fixed',
    }}>
    <Grid item>
      <Skeleton variant="rectangular" height={25} width={300} />
    </Grid>
  </Grid>
);
