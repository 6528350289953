import type { Radio } from '@amzn/sitc-frontend/utils/radio';
import React from 'react';

import { channelName, eventsMap } from '../configs/events';
import { MaybeChannel, MaybeChannelAdmin, Publish, Subscribe } from '../types/events';

type EventsContextType = {
  publish: Publish;
  subscribe: Subscribe;
};

interface EventsProviderProps {
  radio: Radio;
  children: React.ReactNode | React.ReactNode[];
}

export const EventsContext = React.createContext<EventsContextType | null>(null);

export const EventsProvider: React.FC<EventsProviderProps> = ({ radio, children }) => {
  const owner = React.useRef<MaybeChannelAdmin>(radio.getOwnChannel(channelName));
  const channel = React.useRef<MaybeChannel>(radio.getChannel(channelName));

  React.useEffect(() => {
    Object.entries(eventsMap).forEach(([eventName, eventSchema]) => {
      owner.current?.register(eventName, eventSchema);
    });
    return () => {
      Object.keys(eventsMap).forEach((eventName) => {
        owner.current?.unregister(eventName);
      });
    };
  }, []);

  const publish: Publish = React.useCallback((name, eventDetail) => channel.current?.publish(name, eventDetail), []);

  const subscribe: Subscribe = React.useCallback(
    (name, callback, options = { replay: true }) => channel.current?.subscribe(name, callback, options),
    []
  );

  const contextValue = React.useMemo(
    () => ({
      publish,
      subscribe,
    }),
    []
  );

  return <EventsContext.Provider value={contextValue}>{children}</EventsContext.Provider>;
};
