import { useIdToken, useUserPoolMicroAppName } from '@amzn/sitc-frontend/contexts';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider as ApolloClientProvider,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import React, { useContext, useEffect, useState } from 'react';

import { AppLoading } from '../components/commons/AppLoading';
import { MicroAppConfigContext } from '../utils/app';

interface Props {
  children?: React.ReactNode;
}

const cache = new InMemoryCache({});

export const ApolloProvider = ({ children }: Props) => {
  const [apolloClient, setApolloClient] = useState<ApolloClient<NormalizedCacheObject> | null>(null);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { apiBaseUrl, appId } = useContext(MicroAppConfigContext);
  const idToken = useIdToken();
  const userPoolMicroAppName = useUserPoolMicroAppName() || '*';

  useEffect(() => {
    if (idToken) {
      const isdgLink = createHttpLink({
        uri: `${apiBaseUrl}/api/graphql/isdg`,
      });

      const authLink = setContext((setter, { headers }) => ({
        headers: {
          ...headers,
          authorization: `Bearer ${idToken}`,
          appid: appId,
          'micro-app-name': userPoolMicroAppName,
        },
      }));

      setApolloClient(
        new ApolloClient({
          link: ApolloLink.from([authLink, isdgLink]),
          cache,
        })
      );
    }
  }, [idToken, userPoolMicroAppName]);

  if (!apolloClient) {
    return <AppLoading />;
  }

  return <ApolloClientProvider client={apolloClient}>{children}</ApolloClientProvider>;
};
