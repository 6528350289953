import '../../i18n';

import {
  AlertContextProvider,
  AuthSessionProvider,
  CommonUserPreferencesProvider,
  MicroAppConfigProvider,
  ThemeProvider,
} from '@amzn/sitc-frontend/contexts';
import { MicroAppInitializationProps } from '@amzn/sitc-frontend/types';
import i18n from 'i18next';
import React, { Suspense } from 'react';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import { microAppName } from '../../configs/app';
import { ApolloProvider } from '../../contexts/Apollo';
import { EventsProvider } from '../../contexts/Events';
import { FeatureConfiguration } from '../../contexts/FeatureConfiguration';
import { UniversalHeaderProvider } from '../../contexts/UniversalHeader';
import { UserDataProvider } from '../../contexts/UserData';
import { appDomainTemplate, MicroAppConfigContext, MicroAppConfigResultType } from '../../utils/app';
import { SkeletonLoader } from '../commons/SkeletonLoader';

const UniversalHeaderDemoShell = React.lazy(() =>
  import('./UniversalHeaderDemoShell').then(({ UniversalHeaderDemoShell: shell }) => ({ default: shell }))
);

const UniversalHeaderDemo: React.FC<MicroAppInitializationProps> = (props) => {
  const { appId, authSession, basePath, stage, radio, userPreferences, featureOverrides } = props;
  const { theme } = userPreferences.preferences;

  return (
    <MicroAppConfigProvider<MicroAppConfigResultType>
      appDomainTemplate={appDomainTemplate}
      appId={appId}
      appLoader={<SkeletonLoader />}
      appName={microAppName}
      context={MicroAppConfigContext}
      stage={stage}>
      <I18nextProvider i18n={i18n}>
        <BrowserRouter basename={basePath}>
          <FeatureConfiguration featureOverrides={featureOverrides}>
            <CommonUserPreferencesProvider userPreferences={userPreferences} radio={radio} source={microAppName}>
              <ThemeProvider themePreference={theme}>
                <AlertContextProvider closeOnLocationChange={false} snackBarStyle={{ top: '6.75rem !important' }}>
                  <AuthSessionProvider authSession={authSession}>
                    <EventsProvider radio={radio}>
                      <ApolloProvider>
                        <UserDataProvider authSession={authSession}>
                          <UniversalHeaderProvider>
                            <Suspense>
                              <UniversalHeaderDemoShell />
                            </Suspense>
                          </UniversalHeaderProvider>
                        </UserDataProvider>
                      </ApolloProvider>
                    </EventsProvider>
                  </AuthSessionProvider>
                </AlertContextProvider>
              </ThemeProvider>
            </CommonUserPreferencesProvider>
          </FeatureConfiguration>
        </BrowserRouter>
      </I18nextProvider>
    </MicroAppConfigProvider>
  );
};

export default UniversalHeaderDemo;
