import { FeatureFlagsProvider } from '@amzn/sitc-frontend/contexts';
import { Features } from '@amzn/sitc-frontend/types';
import React, { useContext } from 'react';

import { MicroAppConfigContext } from '../utils/app';

interface FeatureConfigurationProps {
  children?: React.ReactNode;
  featureOverrides: Features;
}

export const FeatureConfiguration: React.FC<FeatureConfigurationProps> = ({ featureOverrides, children }) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { appConfig } = useContext(MicroAppConfigContext);

  return (
    <FeatureFlagsProvider featureOverrides={featureOverrides} defaultFeatures={appConfig?.app.features}>
      {children}
    </FeatureFlagsProvider>
  );
};
