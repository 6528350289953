import { configure as configureSchema, configured as configuredSchema } from '../schemas/configure-event';

export const channelName = 'universal-header' as const;

export enum EventNames {
  Configure = 'configure',
  Configured = 'configured',
}

export const eventsMap = {
  [EventNames.Configure]: configureSchema,
  [EventNames.Configured]: configuredSchema,
};
