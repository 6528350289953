import { StandaloneConfiguration } from '@amzn/sitc-frontend/components';
import React from 'react';
import ReactDOMClient from 'react-dom/client';
import singleSpaReact from 'single-spa-react';

import { ErrorBoundary } from './components/commons/ErrorBoundary';
import UniversalHeaderDemo from './components/standalone/UniversalHeaderDemo';
import StudiosUniversalHeaderApp from './components/StudiosUniversalHeaderApp';

const configuration: Record<string, unknown> = {
  microAppName: 'amzn-studios-universal-header-app',
  featureOverrides: { enableUserProfileApp: true, enableThemeToggle: true },
};

const radioConfig: Record<'ownChannelNames' | 'channelNames', string[]> = {
  channelNames: ['universal-header'],
  ownChannelNames: ['universal-header'],
};

const rootComponent = process.env.STANDALONE
  ? () => <StandaloneConfiguration RootComponent={UniversalHeaderDemo} configuration={configuration} radioConfig={radioConfig} />
  : StudiosUniversalHeaderApp;

const lifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent,
  errorBoundary: (error: Error) => <ErrorBoundary error={error} />,
});

export const { bootstrap, mount, unmount } = lifecycles;
