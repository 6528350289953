export const configure = {
  type: 'object',
  properties: {
    appName: {
      type: 'string',
    },
    config: {
      type: 'object',
      properties: {
        productIdentifier: {
          type: 'string',
        },
        productIdentifierPath: {
          type: 'string',
        },
      },
      additionalProperties: true,
    },
  },
  required: ['appName', 'config'],
};

export const configured = {
  type: 'object',
  properties: {
    appName: {
      type: 'string',
    },
    success: {
      type: 'boolean',
    },
  },
  required: ['appName', 'success'],
};
